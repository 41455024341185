import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { BaseComponentProps } from '@/types';
import ServicesSectionStyled from './ServicesSectionStyled';
import Reveal from '@/components/reveal/Reveal';

const ServicesSection: React.FC<BaseComponentProps> = () => (
  <ServicesSectionStyled className="services-section">
    <div className="container">
      <Reveal className="section-header">
        <h2>Services</h2>
      </Reveal>

      <div className="services">
        <Reveal className="service">
          <p>
            We help the world’s top brands keep pace with constantly evolving
            consumer needs, unlocking new opportunities to drive growth through
            digital commerce.
          </p>
        </Reveal>

        <Reveal className="service">
          <div className="service-link-container">
            <a href="/services#technology" className="service-link">
              <span className="service-link-icon">
                <StaticImage
                  src="../../../static/assets/images/tech-icon.png"
                  width={40}
                  height={40}
                  alt="Technology"
                  loading="lazy"
                />
              </span>

              <div className="service-link-content">
                <h3>Technology</h3>

                <p>Best-of-breed commerce platforms and technologies</p>
              </div>
            </a>

            <a href="/services#strategy" className="service-link">
              <span className="service-link-icon">
                <StaticImage
                  src="../../../static/assets/images/strategy-icon.png"
                  width={40}
                  height={40}
                  alt="Strategy"
                  loading="lazy"
                />
              </span>

              <div className="service-link-content">
                <h3>Strategy</h3>

                <p>Digital strategy, transformation and business design</p>
              </div>
            </a>

            <a href="/services#experience" className="service-link">
              <span className="service-link-icon">
                <StaticImage
                  src="../../../static/assets/images/experience-icon.png"
                  width={40}
                  height={40}
                  alt="Experience"
                  loading="lazy"
                />
              </span>

              <div className="service-link-content">
                <h3>Experience</h3>

                <p>Customer experience, brand experience and UI design</p>
              </div>
            </a>
          </div>
        </Reveal>
      </div>
    </div>
  </ServicesSectionStyled>
);

export default ServicesSection;
